import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'home',
        redirect: '/login',
        component: () =>
            import ('../views/page/course/course.vue'),


    },
    //登录页
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/login/login.vue'),
    },
    //我的课程-视频
    {
        path: '/courseVideo',
        name: 'courseVideo',
        component: () =>
            import ('../views/page/course/courseVideo.vue'),
    },


    {
        path: '/home',
        name: 'home',
        redirect: '/home/course',
        component: () =>
            import ('../views/page/home.vue'),

        children: [{
                path: '/',
                name: 'course',

                component: () =>
                    import ('../views/page/course/course.vue'),

            },
            //我的课程
            {
                path: 'course',
                name: 'course',
                component: () =>
                    import ('../views/page/course/course.vue'),
            },

            {
                path: 'questionBank',
                name: 'questionBank',
                component: () =>
                    import ('../views/page/questionBank/questionBank.vue'),
            },
            {
                path: 'questionBank/practice',
                name: 'questionBank/practice',
                component: () =>
                    import ('../views/page/questionBank/practice.vue'),
            },



            {
                path: 'questionBank/answer',
                name: 'questionBank/answer',
                component: () =>
                    import ('../views/page/questionBank/answer.vue'),
            },
            {
                path: 'questionBank/statistics',
                name: 'questionBank/statistics',
                component: () =>
                    import ('../views/page/questionBank/statistics.vue'),
            },
            { //查询解析
                path: 'questionBank/seeAnalysis',
                name: 'questionBank/seeAnalysis',
                component: () =>
                    import ('../views/page/questionBank/seeAnalysis.vue'),
            },
            //我的错题
            {
                path: 'wrongTopic',
                name: 'wrongTopic',
                component: () =>
                    import ('../views/page/wrongTopic/wrongTopic.vue'),
            },
            //我的错题-章节选项
            {
                path: 'wrongTopic/chapterOptions',
                name: 'wrongTopic/chapterOptions',
                component: () =>
                    import ('../views/page/wrongTopic/chapterOptions.vue'),
            },
            // 我的错题 - 错题解析 
            {
                path: 'wrongTopic/wrongAnalysis',
                name: 'wrongTopic/wrongAnalysis',
                component: () =>
                    import ('../views/page/wrongTopic/wrongAnalysis.vue'),
            },
        ]


    },
    {
        path: '/404',
        component: () =>
            import ('@/views/404'),
        hidden: true
    }, {
        path: '*',
        redirect: '/404',
        hidden: true
    }


]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router