import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageWidth: ''
  },
  mutations: {
    // 保存宽度
    saveWidth(state,pageWidth){
      state.pageWidth = pageWidth;
    },
  },
  actions: {
    saveWidth({commit}, payload){
      commit('saveWidth', payload)
    }
  },
  modules: {
  }
})
